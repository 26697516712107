import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaCartShopping, FaCheckDouble, FaFacebook, FaInstagram, FaLocationArrow, FaLocationDot, FaPlus, FaTrash, FaTwitter, FaWhatsapp, FaX, FaYoutube } from "react-icons/fa6";
import image1 from './images/slider1.jpeg';
import image2 from './images/slider2.jpeg';
import image3 from './images/slider3.jpeg';
import image4 from './images/slider4.jpeg';
import logoHouse from './images/logos/logo.png'
import logoKasr from './images/logos/logo (1).png'
import logoName from './images/logos/logo (2).png'
import noimage from './images/noimage.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './index.css'
import 'swiper/css/navigation';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import cadretop from './images/cadretop.png';
import cadrebottom from './images/cadrebottom.png'
// import required modules
import { Pagination , Navigation,Autoplay} from 'swiper/modules';
import logo from './images/logo.png'

export default function App() {
  const [items,setItems] = useState(null);
  const [categories,setCategories] = useState(null);
  const [activeCategorie,setActiveCategorie]= useState(null);
  const  [AllItems,setAllItems  ] = useState(null);
  const [cart,setCart] = useState([]);
  const[openCart,setOpenCart] = useState(false);
  const [loading,setLoading] = useState(false);
  const [total,setTotal] = useState(0);
  const [activeSlide,setActiveSlide]= useState(0);
  const [rate,setRate] = useState(0);


  useEffect(() => {
    AOS.init();
  }, [])
useEffect(()=>{
   setLoading(true);
  axios.get("https://api.softpro.me/CustomersAPIs/768062665/index.php").then((res)=>{
    setLoading(false)
    setCategories(res.data.categories);
    setActiveCategorie(res.data.categories[0]);
    setRate(res.data.setting);
  setAllItems(res.data.items);
  })
},[])


useEffect(() => {
  const handleScroll = () => {
    let butns = document.getElementsByClassName("categories-btns");
    for (let i = 0; i < butns.length; i++) {
      if (window.scrollY > 200) {
        butns[i].classList.add("active");
      } else {
        butns[i].classList.remove("active");
      }
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


function deleteFromCart(index) {
  setCart(prevCart => prevCart.filter((_, idx) => idx !== index));
  showMessg('Item removed from cart');
}


function addTocart(item) {
  const itemToAdd = {
    id: item.id,
    name: item.description,
    price: Number(item.pricell).toFixed(0),
    image:item.pic,
    quantity: 1
  };

  if (cart.some(cartItem => cartItem.id === itemToAdd.id)) {
    // Item is already in the cart
    setCart(prevCart => prevCart.map(cartItem => {
      if (cartItem.id === itemToAdd.id) {
        return { ...cartItem, quantity: cartItem.quantity + 1 };
      } else {
        return cartItem;
      }
    }));
    showMessg('Item added to cart');
  } else {
    setCart(prevCart => [...prevCart, itemToAdd]);
    showMessg('Item added to cart');
  }
}

useEffect(()=>{
  console.log(cart)
},[cart])

useEffect(()=>{
  if(AllItems){
  setItems(AllItems.filter(item => item.idSubC == activeCategorie.idsubc));
  }
},[activeCategorie,AllItems])

function filter(category){

setActiveCategorie(category);
window.scrollTo({ top: 0, behavior: 'smooth' });
 
}



function increaseQuantity(itemId) {
  setCart(prevCart =>
    prevCart.map(cartItem =>
      cartItem.id === itemId ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
    )
  );
}

function decreaseQuantity(itemId) {
  setCart(prevCart =>
    prevCart.map(cartItem =>
      cartItem.id === itemId && cartItem.quantity > 1
        ? { ...cartItem, quantity: cartItem.quantity - 1 }
        : cartItem
    )
  );
}

  const showMessg=(message)=>{
    toastr.options = {
      positionClass :'toast-bottom-full-width',
      hideDuration: 300,
      timeOut: 1000,
      escapeHtml:false,
      closeButton:true,
      newestOnTop : false,
      closeEasing : 'swing'
    }
    toastr.clear()
    setTimeout(() => toastr.success(message))
  }

  useEffect(() => {
    let total = 0; // Initialize total variable
  
    // Calculate total based on cart items
    cart.forEach((item) => {
      total += item.price * item.quantity;
    });
  
    // Update the total state
    setTotal(total.toFixed(0));
  }, [cart]);



  function sendWhatsApp() {

    // Construct message with cart details
    let message = "*My Cart* :\n";
    message += "-------------------------------- \n"
    cart.forEach((item,index) => {
      message += `*I ${index+1}* _ *${item.name}* - Quantity: ${item.quantity}, Price: ${ Number(item.price * item.quantity).toFixed(0)}\n`;
    });
    message += "-------------------------------- \n"
    message += `Total: ${Number(total).toFixed(0)}`;
  
    // Encode message for URL
    const encodedMessage = encodeURIComponent(message);
  
    // Open WhatsApp with pre-filled message
    setTotal(0)
    setCart([]);
    setOpenCart(false);
    window.scrollTo({top:0})
    window.open(`https://api.whatsapp.com/send?phone=03279925&text=${encodedMessage}`);
    
  }


const [prevSlide,setPrevSlide] = useState(0)
useEffect(()=>{
  document.getElementById(`slide_${prevSlide}`).classList.remove('slide-anime');
  document.getElementById(`slide_${activeSlide}`).classList.add('slide-anime');
 
 setPrevSlide(activeSlide);

},[activeSlide])
const backgroundStyle = {

  padding: '20px',
  borderRadius: '10px',
  clipPath: 'polygon(0 0, 100% 0, 82% 100%, 16% 100%)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  // marginBottom: '20px',
};
  return (


    <>


    <div   className=' aspect-[16/9] md:aspect-[16/5] border-b-4 border-black w-full '>
      <Swiper
      onSlideChange={(slide) => setActiveSlide(slide.activeIndex)}
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
        
        autoplay={true}
        modules={[Pagination,Navigation,Autoplay]}
        className="mySwiper"
      >


        <SwiperSlide >
          <img src={image1}/>
          <div className='  absolute z-50 w-full h-full top-0 left-0 '>
            <div className=' bg-gradient-to-r from-[#333333] relative  flex flex-col justify-center h-full  px-5  text-left gap-4 w-[90%]'>
            <div id='slide_0' className='  slide-anime flex flex-col gap-3 w-32 md:w-60  '>
    <img className='  mx-auto' src={logoName}/>
    <img className='  mx-auto' src={logoHouse}/>
    <img className='  mx-auto' src={logoKasr}/>
  </div>
              
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide  ><img src={image2}/>
        <div className='  absolute z-50 w-full h-full top-0 left-0 '>
            <div className=' bg-gradient-to-r from-[#333333] relative  flex flex-col justify-center h-full  px-5  text-left gap-4 w-[90%]'>
            <div id='slide_1' className='   flex flex-col gap-3  w-32 md:w-60'>
    <img className='  mx-auto' src={logoName}/>
    <img className='  mx-auto' src={logoHouse}/>
    <img className='  mx-auto' src={logoKasr}/>
  </div>
              
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide >
          <img src={image3}/>
          <div className='  absolute z-50 w-full h-full top-0 left-0 '>
            <div className=' bg-gradient-to-r from-[#333333] relative  flex flex-col justify-center h-full  px-5  text-left gap-4 w-[90%]'>
            <div id='slide_2' className='   flex flex-col gap-3  w-32 md:w-60'>
    <img className='  mx-auto' src={logoName}/>
    <img className='  mx-auto' src={logoHouse}/>
    <img className='  mx-auto' src={logoKasr}/>
  </div>
              
            </div>
          </div>
          </SwiperSlide>
        <SwiperSlide  >
          <img src={image4}/>
          <div className='  absolute z-50 w-full h-full top-0 left-0 '>
            <div className=' bg-gradient-to-r from-[#333333] relative  flex flex-col justify-center h-full  px-5  text-left gap-4 w-[90%]'>
            <div id='slide_3' className='   flex flex-col gap-3  w-32 md:w-60'>
    <img className='  mx-auto' src={logoName}/>
    <img className='  mx-auto' src={logoHouse}/>
    <img className='  mx-auto' src={logoKasr}/>
  </div>
              
            </div>
          </div>
          </SwiperSlide>
          <SwiperSlide >
          <img src='https://scontent.fbey2-2.fna.fbcdn.net/v/t39.30808-6/302725506_7913940902011238_3626608574734576337_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Daad3yWjTOMAb6BfGqj&_nc_ht=scontent.fbey2-2.fna&oh=00_AfCtYreW3PnFzlOkl-Pkw-aeKArQKySjR02Jexch_IIHYw&oe=6632E75C'/>
          <div className='  absolute z-50 w-full h-full top-0 left-0 '>
            <div className=' bg-gradient-to-r from-[#333333] relative  flex flex-col justify-center h-full  px-5  text-left gap-4 w-[90%]'>
            <div id='slide_4' className='  slide-anime flex flex-col gap-3 w-32 md:w-60  '>
    <img className='  mx-auto' src={logoName}/>
    <img className='  mx-auto' src={logoHouse}/>
    <img className='  mx-auto' src={logoKasr}/>
  </div>
              
            </div>
          </div>
          </SwiperSlide>
      </Swiper>
      </div>

    {loading? <div className={`  pt-2 mb-12 flex   flex-col gap-3  w-full h-full`} >
      <div className='  md:px-12 min-w-full flex  flex-row relative overflow-x-auto gap-4'>
      <div className="placeholder_animation h-20  w-[7rem]  bg-white  flex-grow-1 "></div>
      <div className="placeholder_animation h-20  w-[7rem]  bg-white  flex-grow-1 "></div>
      <div className="placeholder_animation h-20  w-[7rem]  bg-white  flex-grow-1 "></div>
      <div className="placeholder_animation h-20  w-[7rem]  bg-white  flex-grow-1 "></div>
      </div>
         <div className="placeholder_animation h-16   bg-white mt-4 mb-6"></div>
       
                 <div className="mb-4  grid  grid-cols-2 w-full gap-2  ">
                     <div className="placeholder_animation  w-full h-32"></div>
                     <div className="placeholder_animation  w-full h-32"></div>
                     <div className="placeholder_animation  w-full h-32"></div>
                     <div className="placeholder_animation  w-full h-32"></div>
                     <div className="placeholder_animation  w-full h-32"></div>
                     <div className="placeholder_animation  w-full h-32"></div>
                 </div>
                 
     </div>
     :

  




<>
<div className=' container mx-auto max-md:px-2 flex flex-col gap-5'>
{categories&& <div  className=' sticky mx-auto w-full top-0 bg-white z-30 py-3'>
        <div className=' min-w-full flex  flex-row relative overflow-x-auto gap-4 '>
          { categories.map((category)=><button onClick={()=>filter(category)}
           className={` min-w-10 categories-btns   transition-all relative overflow-hidden bg-amber-500  flex justify-center text-center rounded-md`}>

           <img className=' object-cover w-full h-full filter '  src='https://qrlist.app/_cache_ipx/fit_cover&f_webp&q_80&loading_lazy&s_500x500/https://qrlist.app/storage/uploads/imageItem/cdd142db254bf799275eb7970cbbb586.jpg'/>
            <div className={` ${activeCategorie.idsubc == category.idsubc? "bg-amber-500":" bg-black"} w-full h-full inset-0  bg-opacity-30  absolute`}></div>
            <h2 className=' font-bold  absolute left-1 -bottom-2 ibm-plex-sans-arabic-medium  my-5 text-white'>{category.description}</h2>
            
            </button>) 

     }
        </div>
      </div>}
 {activeCategorie&& <div  className=' w-full md:w-1/3 mx-auto flex flex-col gap-3 justify-center text-center'>
  <img data-aos="fade-up"   data-aos-delay="0" src={cadretop}></img>
  <h2 data-aos="fade-up" className=' md:text-3xl md:font-bold'   data-aos-delay="100">{activeCategorie.description}</h2>
  <img data-aos="fade-up"   data-aos-delay="200" src={cadrebottom}></img>
  </div>}

<div className=' grid w-full overflow-hidden grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2'>
{items && items.map((item,index)=>  item.pricell>0&& <div data-aos="fade-up" {...(index % 2 === 0 ? { "data-aos-delay": "0" } : { "data-aos-delay": "100" })}  key={item.id} className='w-full  md:h-80  h-64 overflow-hidden relative rounded-md shadow-md flex flex-col'>
   {item.pic? 
    <div className=' h-[70%] w-full'>
   <img 
     
     className='h-full w-full product-image'  src={`https://api.softpro.me/CustomersAPIs/768062665/images/${item.pic}?id=${item.id}`}/>
    </div>
     :  
     <div className=' h-[70%] w-full'>
     <img 
     
  className='   w-full h-full   product-image' src={noimage}/>
</div>
    }
     <div className='  h-[30%] bg-[#333333] w-full'>
      <div className=' px-1 py-2 '>
        <div className=' flex gap-2 flex-col '>
          <h2 className=' text-base  ibm-plex-sans-arabic-light   text-white' >{item.description} </h2>
         <div  className=' flex flex-row  justify-between w-full text-center'> <span className=' text-sm font-bold text-amber-500'>{Number(item.pricell).toFixed(0)}L.L</span>
         <button onClick={()=>{addTocart(item)}}  className=' w-6 h-6  flex justify-center text-center bg-amber-500 text-white rounded-full text-lg  font-bold'><FaPlus className=' my-auto text-sm'/></button></div>
        </div>
      </div>
     </div>
    </div>)} 
  
   

 

  </div>
</div>


<div className='flex flex-col my-8 gap-5 '>
  <div className=' text-xl flex flex-row justify-center gap-4 w-full text-center'>
    <a data-aos="fade-up"   data-aos-delay="0"  href='https://api.whatsapp.com/send?phone=03279925'>
  <FaWhatsapp/>
  </a>
    <a data-aos="fade-up"   data-aos-delay="50" href='https://www.facebook.com/profile.php?id=100064814735596'>
    <FaFacebook/>
    </a>
   
    <a data-aos="fade-up"   data-aos-delay="100" href='https://www.instagram.com/explore/locations/835777966494269/'>
    <FaInstagram/>
    </a>

    <a data-aos="fade-up"   data-aos-delay="150" href='https://maps.app.goo.gl/W3t5jJJENwV3BMF88'>
      <FaLocationDot/>
    </a>
   
  </div>
  
  <div data-aos="fade-up" > 
    <img className=' h-48 aspect-square mx-auto' src={logo}/>
  </div>

  <div className='  rounded-md shadow-md py-2 mx-2 border-t border-gray-500 text-gray-500 text-sm flex flex-col gap-2 justify-center text-center'>
    <FaLocationDot className=' mx-auto'/>
    <h2 className='ibm-plex-sans-arabic-light '>فرن درغام للكعك الطرابلسي الاصيل-خالي من الملح
جملة و مفرّق
طرابلس-ساحة الكيال مقابل صيدلية علم</h2>
  </div>
  <div className='flex flex-row justify-center divide-x-2  divide-slate-300 text-center  '>
    <a  className=' pr-2 block text-sm text-gray-500' href='tel:03279925'>Tel: 03 279 925</a>
  
    </div>

</div>
<div onClick={()=>setOpenCart(true)} className=' fixed  -right-2 h-16 w-16 bg-white shadow-lg  border-t-4 border-b-4 border-l-4  border-amber-500  bottom-3 rounded-full '>
 <div className=' relative w-full h-full'>
  <div className=' absolute h-5 w-5 bg-[#333333] rounded-full text-white flex justify-center text-center '>
  <span class={`relative  transition-all  flex h-full w-full`}>
  <span className=' absolute z-50  right-[30%] font-bold top-[10%] mx-auto my-auto text-xs'>{cart.length}</span>
  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-500 opacity-75">

  </span>
  <span class="relative inline-flex rounded-full h-full w-full bg-[#333333]"></span>
</span>

    </div>
  <FaCartShopping className=' h-full my-auto mx-auto text-xl'/>
  </div>
  </div>



   {openCart &&<div onClick={()=>setOpenCart(false)} className=' fixed w-full h-full z-40 bg-black bg-opacity-35 inset-0'></div>}
  <div className={` fixed z-50 w-full  shadow-xl  ${openCart?"h-[500px]":" h-0"} border-t-4 border-amber-500 bg-white transition-all duration-300 ease-out bottom-0  left-0 right-0 rounded-lg `}>
    <div className=' flex flex-col gap-3  justify-between h-full relative'>
    <div className=' justify-between flex flex-row w-full px-3 py-2'>
      <h2 className=' text-2xl font-bold '>Cart</h2>
     <button onClick={()=>setOpenCart(false)}><FaX/></button>
    </div>
    <div className=' max-h-full flex-col flex justify-items-start h-full   relative overflow-y-auto'>
      {cart.map((itemCart,index)=>{
        return <div className='px-2 flex flex-row justify-between   w-full  py-3 shadow-md'>
        <div className='  flex flex-row justify-start gap-3  '>
        <div className=' w-20 '>
       {itemCart.image? <img  src={`https://api.softpro.me/CustomersAPIs/768062665/images/${itemCart.image}?id=${itemCart.id}`}/>: <img 
     
     className='  ' src={noimage}/>
     }
         
        </div>
        <div className=' flex flex-col justify-between h-full '> 
        <h2>{itemCart.name}</h2>
        <span className=' text-xs font-bold text-amber-500'>{itemCart.price}L.L</span>
        </div>
        </div>
        <div>
          </div>



          <div className='flex gap-4  flex-col justify-between h-full'>
         
         <div className=' w-full flex  justify-end text-end'> <button onClick={()=>deleteFromCart(index)} className=' text-right  p-2 bg-red-500 text-xs rounded-full text-white'><FaTrash/></button></div>
          <div className=' flex justify-center gap-2'>
            
            <button onClick={()=>increaseQuantity(itemCart.id)} className=' p-2 border  border-gray-400  '>+</button>
           <h2 className=' my-auto font-bold text-xs'> {itemCart.quantity}</h2>
            <button onClick={()=>decreaseQuantity(itemCart.id)} className=' p-2 border  border-gray-400  '>-</button>
           </div>
          </div>
        </div>
      })}
    </div>




    <div className=' px-2 flex justify-between gap-3 w-full py-2'>
      <div className=' flex flex-col justify-center w-full text-left '>
        <h2 className=' font-bold text-3xl text-red-800 '>Total:</h2>
        <span className='font-bold text-2xl text-amber-600 '>{total}L.L</span>
      </div>
      <button onClick={()=>{

        sendWhatsApp()
      }} className=' py-2 font-bold text-amber-500  border-amber-500 border-4 rounded-md w-full flex justify-center gap-3 text-center'><span className=' text-xl my-auto'>Submit</span><FaCheckDouble className=' text-xl my-auto'/></button>
      
    
    </div>
   
    </div>
  </div>
  </>}
    </>
  );
}
